import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import ReportFilters from "../../../../Components/corporate/reports/ReportFilters";
import {
  companyObject,
  corporateAccountObject,
  isAgencyUser,
  isRoleEdgeAdmin,
  isSuperAgent,
} from "../../../../modules/permissions";
import {
  companyUnload,
  corporateDepartmentsUnload, fetchAgenciesForSelect, fetchCompanies,
  fetchCompaniesForEvents,
  fetchCompany,
  fetchCorporateAccount,
  fetchCorporateDepartments,
  fetchSpendReport,
  spendReportUnload,
  unloadCompanyList,
  unloadCorporateAccount,
} from "../../../../redux/actions";
import Loading from "../../../../Components/Loading";
import { token } from "../../../../modules/auth";
import SpendReportTable from "../../../../Components/corporate/reports/SpendReportTable";
import { fetchVenuesFromHotelGroup } from "../../../../redux/actions/venues";
import SpendReportCharts from "../../../../Components/corporate/reports/SpendReportCharts";
import html2canvas from "html2canvas";
import printJS from "print-js";
import { ButtonPrimary } from "../../../../Components/Button";
import { camelCaseToWords, listFormatter, removeEmptySearchParams } from "../../../../utils";
import * as ROUTES from "../../../../Router/corporate_routes";
import { saveData } from "../../../../services/downloadFile";
import { format } from "date-fns";

const SpendReportContainer = (props) => {
  const {
    corporateAccount,
    spendReport,
    isLoadingSpendReport,
    token,
    fetchSpendReport,
    spendReportUnload,
    fetchVenuesFromHotelGroup,
    venues,
    companies,
    isAgency,
    isSuperAgent,
    fetchCompaniesForEvents,
    unloadCompanyList,
    corporateDepartments = [],
    fetchCorporateDepartments,
    companyObject,
    unloadCorporateAccount,
    corporateDepartmentsUnload,
    fetchCorporateAccount,
    errorData,
    selectedAgencies,
    isFetchingCompany,
    isRoleEdgeAdmin,
    fetchAgenciesForSelect,
    agenciesForSelect,
    fetchCompanies
  } = props;
  const [company, setCompany] = useState(companyObject);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [hasSearched, setHasSearched] = useState(false);
  const [showPdfContainer, setShowPdfContainer] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState();

  const logo = corporateAccount?.logo_link ? (corporateAccount?.logo_link ? corporateAccount?.cdn_path : "/img/CE-logo-alt.png") : "/img/logo.png";
  const logoAlt = corporateAccount?.logo_link ? corporateAccount.account_name + " logo" : "EDGE Venues logo";

  useEffect(() => {
    if (isAgency) {
      fetchCompaniesForEvents(token);
    }
    return () => {
      spendReportUnload();
      unloadCompanyList();
    };
  }, [selectedAgencies]);

  useEffect(() => {
    if (isRoleEdgeAdmin) {
      fetchAgenciesForSelect(token, true);
    }
  }, [])

  useEffect(() => {
    if (isRoleEdgeAdmin && selectedAgency) {
      fetchCompanies(token, null, { agencyId: selectedAgency }, true);
    }
  }, [isRoleEdgeAdmin, company, selectedAgency]);

  useEffect(() => {
    if (corporateAccount) {
      fetchCorporateDepartments(corporateAccount.id, token);
    }
  }, [corporateAccount]);

  useEffect(() => {
    setCompanyUsers(companyObject ? companyObject.users : []);
    setCompany(companyObject);
  }, [companyObject]);

  const runSpendReport = useCallback(queryFilters => {
    setHasSearched(true);
    setQueryParams(queryFilters);
    delete queryFilters.page;
    // if (Object.keys(company).length > 0) {
    //   queryFilters.company = company?.id
    // }
    return fetchSpendReport(corporateAccount.id, token, queryFilters);
  }, [company, fetchSpendReport, token]);

  const resetSpendReport = useCallback(() => {
    spendReportUnload();
    unloadCorporateAccount();
    corporateDepartmentsUnload();
    setHasSearched(false);
  }, [spendReportUnload, unloadCorporateAccount, corporateDepartmentsUnload]);

  const getVenuesForHotelGroup = group => {
    return fetchVenuesFromHotelGroup(group, token);
  };

  const savePdf = useCallback(() => {
    setShowPdfContainer(true);
    const domElement = document.getElementById("pdfContainer");
    const filtersSummary = document.getElementById("filtersSummary");
    const logoContainer = document.getElementById("logo");
    html2canvas(domElement).then(canvas => {
      const img = canvas.toDataURL();
      printJS(
        {
          printable: img,
          type: "image",
          base64: true,
          documentTitle: "EDGE Venues",
          onPrintDialogClose: () => {
            logoContainer.classList.add("hidden");
            filtersSummary.classList.add("hidden");
          },
        },
      );
    });
    if (!filtersSummary.classList.contains("hidden") || !logoContainer.classList.contains("hidden")) {
      logoContainer.classList.add("hidden");
    }
  }, []);

  const getCompany = useCallback((companyId) => {
    companyUnload();
    return fetchCompany(companyId, token);
  }, [token, fetchCompany, companyUnload]);

  const getCorporateAccount = useCallback((corporateAccountId) => {
    if (corporateAccountId) {
      fetchCorporateAccount(corporateAccountId, token);
      fetchCorporateDepartments(corporateAccountId, token);
    } else {
      unloadCorporateAccount();
      corporateDepartmentsUnload();
    }
  }, [fetchCorporateAccount, fetchCorporateDepartments, token, unloadCorporateAccount, corporateDepartmentsUnload]);

  const getReportFiltersSummary = useMemo(() => {
    return Object.entries(queryParams).map(([key, value]) => {
      if (key === "department") {
        const departmentsArray = corporateDepartments?.filter(dept => value?.includes(dept.id)).map(dept => dept.name);
        return departmentsArray.length ? <span>Departments: {listFormatter.format(departmentsArray)}</span> : null;
      }
      if (key === "company") {
        const companiesArray = companies?.filter(com => {
          return (typeof value === "object") ? value?.includes(com.id) : value == com.id;
        }).map(com => com.name);
        return companiesArray?.length ? <span>Companies: {listFormatter.format(companiesArray)}</span> : null;
      }
      if (key === "dateRange") {
        const [dateFrom, dateTo] = value.split(",");
        return (
          <>
            <span>Date From: {format(new Date(dateFrom), "DD/MM/YYYY")}</span><br />
            <span>Date To: {format(new Date(dateTo), "DD/MM/YYYY")}</span><br />
          </>
        );
      }
      return value ? <span>{camelCaseToWords(key)}: {value}</span> : null;
    });
  }, [queryParams, corporateDepartments, companies]);

  const runSpendReportExport = async () => {
    try {
      if (queryParams.page) {
        delete queryParams.page;
      }
      let queryFilters = new URLSearchParams(queryParams);
      removeEmptySearchParams(queryFilters);
      let queryString = queryFilters.toString();
      let response = await axios.get(
        window.API + ROUTES.API.CORPORATE.REPORTS.SPEND_REPORT_EXPORT + "?" + queryString,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        "csv",
        response.data,
        `spend-report.csv`,
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="min-w-full">
      <h1 className="my-4">
        Spend Report
      </h1>
      <ReportFilters
        shouldDisableSubmit={isFetchingCompany}
        runReport={runSpendReport}
        showExport={false}
        resetReport={resetSpendReport}
        getVenuesForHotelGroup={getVenuesForHotelGroup}
        venues={venues}
        showFilters={{
          hotelGroup: true,
          showCount: false,
          country: true,
          town: true,
          county: true,
          bookingAgent: true,
        }}
        corporateAccount={corporateAccount}
        corporateDepartments={corporateDepartments}
        companyUsers={companyUsers}
        companies={companies}
        getCompany={getCompany}
        getCorporateAccount={getCorporateAccount}
        error={errorData && errorData.errors}
        advancedFilters={true}
        isMultiFilters={true}
        isSuperAgent={isSuperAgent}
        agenciesForSelect={agenciesForSelect}
        selectedAgency={selectedAgency}
        setSelectedAgency={setSelectedAgency}
        isEdgeAdmin={isRoleEdgeAdmin}
      />
      {isLoadingSpendReport ? <Loading /> : null}
      {!isLoadingSpendReport && spendReport && Object.keys(spendReport).length > 0 ? (
        <>
          <div id="pdfContainer">
            <div id="logo" className="hidden">
              <img
                src={logo}
                alt={logoAlt}
                style={{
                  height: "60px",
                  width: "135px;",
                  maxHeight: "60px",
                }}
              />
              <p className="mb-0 text-xs">
                Powered by EDGE Venues
              </p>
            </div>
            <div className="overflow-hidden border-b border-gray-200 bg-white p-5">
              <div className="flex-auto">
                <SpendReportCharts report={spendReport} />
                <SpendReportTable report={spendReport} />
              </div>
            </div>
            <div id="filtersSummary" className="pt-4">
              <strong>Report Summary</strong><br />
              {getReportFiltersSummary}
            </div>
          </div>
          <div className={"row mb-2"}>
            <div className={"col flex"}>
              <ButtonPrimary classes={"mt-4 mr-2"} onClick={() => savePdf()}>Export to PDF</ButtonPrimary>
              <ButtonPrimary classes={"mt-4"} onClick={() => runSpendReportExport()}>Export to CSV</ButtonPrimary>
            </div>
          </div>
        </>
      ) : null}
      {!isLoadingSpendReport && hasSearched && spendReport && Object.keys(spendReport).length === 0 ?
        <div className="p-3 bg-warning text-white w-full my-6">
          <p className={"mb-0"}><i className="fas text-2lg fa-info-circle mr-3" />
            There no results found, please amend the filters above and run the report again.
          </p>
        </div>
        : null
      }
      {!hasSearched ?
        <div className="p-3 bg-lighter-blue w-full my-6">
          <p className={"mb-0"}><i className="fas text-2lg fa-info-circle mr-3" />
            Please use the filters above and click run, to view the Spend Report.
          </p>
        </div>
        : null}
    </div>
  );
};

const mapStateToProps = state => {
  let corporateAccount = Object.entries(corporateAccountObject(state.auth)).length > 0 ? corporateAccountObject(state.auth) : state.corporateAccount?.corporateAccount;
  let companyAccount = Object.entries(companyObject(state.auth)).length > 0 ? companyObject(state.auth) : state.company?.company;
  let companies = state.companyList.companiesForEvents ? state.companyList.companiesForEvents.data : [];
  let isEdgeAdmin = isRoleEdgeAdmin(state.auth);
  if (isEdgeAdmin) {
    companies = state.companyList.companies ? state.companyList.companies : [];
  }
  return {
    ...state.selectedAgencies,
    ...state.spendReport,
    ...state.brand,
    ...state.isLoadingSpendReport,
    ...state.venues,
    ...state.corporateDepartments,
    ...state.agenciesForSelect,
    ...state.agencyList,
    companies: companies || [],
    errorData: state.spendReport.errorData,
    isAgency: isAgencyUser(state.auth),
    corporateAccount: corporateAccount,
    token: token(state),
    companyObject: companyAccount,
    isSuperAgent: isSuperAgent(state.auth),
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth)
  };
};

const mapDispatchToProps = {
  fetchCompanies,
  fetchAgenciesForSelect,
  fetchSpendReport,
  spendReportUnload,
  fetchVenuesFromHotelGroup,
  fetchCompany,
  companyUnload,
  fetchCompaniesForEvents,
  fetchCorporateAccount,
  unloadCompanyList,
  fetchCorporateDepartments,
  unloadCorporateAccount,
  corporateDepartmentsUnload,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpendReportContainer);
