import axios from "axios";
import {token} from "../../auth";

const authenticate = () => (d, getState) => {
    d({
        type: "@@analytics/AUTHENTICATE",
        payload: "fetching",
    });
    axios
        .post(`${process.env.REACT_APP_ANALYTICS}/auth`, {
            "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImNjOWFmYWM3MzdiNWJkNjJlZmM1NjhjOTNkNmRhY2U4NjVkMzhjYmVlYTEyOTljYmJiOGRkZDBhYzc3M2YxOGM4MmU3NTZmMmE5YzA0MmFjIn0.eyJhdWQiOiIxIiwianRpIjoiY2M5YWZhYzczN2I1YmQ2MmVmYzU2OGM5M2Q2ZGFjZTg2NWQzOGNiZWVhMTI5OWNiYmI4ZGRkMGFjNzczZjE4YzgyZTc1NmYyYTljMDQyYWMiLCJpYXQiOjE3MjI4NDUyNDksIm5iZiI6MTcyMjg0NTI0OSwiZXhwIjoxNzU0MzgxMjQ5LCJzdWIiOiIxMzI4NyIsInNjb3BlcyI6W119.KysWhTVc8T1r7gW6TWzxfdo6z9JSAHKWiU-WqwCzBkIW6SqRAQcgPFUvbWOnA6Y74cJxr7C5x214YCMWD87QBB968k47YsLLLcRUxnHp6R1r5ONb5qN2W_QIE2Qv3B70UQWxvgBBff3xC5fJWM9Q7p2zqDHFheZ916Dvgqz9ezVFYmVz99XpstpeYzny_AZgMGN95xfWoDMT3FL5vPW3FGDvKfMnvyUN09z0b1MtteO-UxQusMbvAjgx7N8rS_jM3jqX3McyQJ-OqkO6jXqeB5x4vbzStPfAvA5f-sDG98brmYivl3urXtAW7ZuEIpp03wwdgkT0yQ2wSTGvcz1siqJLNqgVkGo3uAWk-tNRpRgFqZACp7NkuzSFgGSz1knLUSs5O2rmjy-ht6RkOu8slkydOwKELN3FIUNUGNptuUKrZ4nXEQTOqlY7yRh2A2rypsix-uTPlVmoyaFn_uuzcRWIfwzjEscknZm0wnNRAFO6guxhkmiOKuVzSd5cq2tcOc02egdW3K9c5HONk3TxjQ8ffhvWvGYO3UzEDxHlb2yD1M7KVPs0_vdSKw5kcD8kf9LjjNFsu0BRvYnEhIOa6L-x5jtftFXHHMVqwsy-P84wlo63ADioqN6Zg4QYAVmxHryA4YJWBjCdX6qjB6sEDG7ZMefV6wSWtl0pf83oc84"
        })
        .then(response => {
            d({
                type: "@@analytics/AUTHENTICATE",
                payload: response.data.token,
            });
        })
        .catch(() => {
            d({
                type: "@@analytics/AUTHENTICATE",
                payload: "error",
            });
        });
};

export {authenticate};
