import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  companyUnload,
  fetchAgenciesForSelect,
  fetchCompanies,
  fetchCompaniesForEvents,
  fetchCompany,
  fetchCorporateAccount,
  fetchSnapshotReport,
  snapshotReportUnload,
  unloadCompanyList,
  unloadCorporateAccount,
} from "../../../../redux/actions";
import { token, user } from "../../../../modules/auth";
import { companyObject, corporateAccountObject, isAgencyUser, isRoleEdgeAdmin } from "../../../../modules/permissions";
import Loading from "../../../../Components/Loading";
import SnapshotReportTable from "../../../../Components/corporate/reports/SnapshotReportTable";
import classnames from "classnames";
import { ButtonPrimary, ButtonSpan } from "../../../../Components/Button";
import { Select } from "../../../../ui";
import { nameFormatter, removeEmptySearchParams } from "../../../../utils";
import * as ROUTES from "../../../../Router/corporate_routes";
import { saveData } from "../../../../services/downloadFile";
import { MultiValue, Option, ValueContainer } from "../../../../Components/corporate/reports/MultiValueComponents";
import MultiSelect from "../../../../ui/MultiSelect";

const SnapshotReportContainer = ({
                                   token,
                                   snapshotReport,
                                   fetchSnapshotReport,
                                   isFetchingSnapshotReport,
                                   ownReport = false,
                                   showFilters = true,
                                   dashboardReport = false,
                                   selectedAgencies,
                                   companies,
                                   fetchCompaniesForEvents,
                                   user,
                                   companyObject,
                                   fetchCompany,
                                   companyUnload,
                                   unloadCompanyList,
                                   company,
                                   isFetchingCompany,
                                   fetchAgenciesForSelect,
                                   isEdgeAdmin,
                                   agenciesForSelect,
                                   isFetchingAgencies,
                                   fetchCompanies,
                                 }) => {
  const [dateFilter, setDateFilter] = useState("created_at");
  const [companyUserOptions, setCompanyUserOptions] = useState([]);
  const [companyId, setCompanyId] = useState(companyObject?.id || null);
  const [companyParams, setCompanyParams] = useState();
  const [companyUser, setCompanyUser] = useState("");
  const [exportingCsv, setExportingCsv] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState();
  let companyOptionsSelect;

  useEffect(() => {
    if (isEdgeAdmin) {
      fetchAgenciesForSelect(token, true);
    }
    if (companyId) {
      let client;
      setCompanyParams(companyId);
      if (ownReport) {
        client = user.id;
        setCompanyUser(user.id);
      }
      if (companyId || client) {
        fetchSnapshotReport(token, {
          dateColumn: dateFilter,
          client,
          companies: companyId,
        });
      }
    }
    return () => {
      companyUnload();
      unloadCompanyList();
    };
  }, []);

  useEffect(() => {
    if (!companyId) return;
    fetchCompany(token);
  }, [companyId]);

  /**
   * When company is loaded get the users for the options select.
   */
  useEffect(() => {
    let options = [];
    company && company.users && company.users.length > 0 && company.users
      .sort((a, b) => {
        return (a.first_name !== null && b.first_name !== null) ? a.first_name.localeCompare(b.first_name) : -1;
      })
      .map(user => {
        options.push({
          text: nameFormatter(user),
          value: user.id,
        });
      });
    setCompanyUserOptions(options);
  }, [company]);

  useEffect(() => {
    if (isEdgeAdmin && selectedAgency) {
      fetchCompanies(token, null, { agencyId: selectedAgency }, true);
    }
  }, [isEdgeAdmin, companyParams, selectedAgency]);

  /**
   * If agencies change, re fetch the companies for the given agencies
   */
  useEffect(() => {
    fetchCompaniesForEvents(token);
  }, [selectedAgencies]);

  /**
   * Do the snapshot report fetching
   */
  useEffect(() => {
    let client;
    if (ownReport) {
      client = user.id;
      setCompanyUser(user.id);
    }
    if (companyUser) {
      client = companyUser;
    }
    if (client) {
      fetchSnapshotReport(token, {
        dateColumn: dateFilter,
        client,
      });
    } else if (companyParams) {
      fetchSnapshotReport(token, {
        dateColumn: dateFilter,
        companies: companyParams,
      });
    }
  }, [company, companyUser, companyParams, dateFilter]);

  const getAgenciesOptions = useMemo(() => {
    if (!isEdgeAdmin) return [];
    if (Object.entries(agenciesForSelect).length === 0 || agenciesForSelect.length === 0 || !agenciesForSelect) return [];
    return agenciesForSelect
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(agency => {
        return {
          label: agency.name,
          value: agency.id,
          text: agency.name,
        };
      });
  }, [agenciesForSelect, isEdgeAdmin]);

  /**
   * Get Companies options
   * @type {[]|*}
   */
  const getCompaniesOptions = useMemo(() => {
    if (!companies || companies.length === 0 || Object.entries(companies).length === 0) return [];
    return companies
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(company => {
        return {
          label: company.name + " - " + company.code + " - " + company?.agency?.name,
          text: company.name + " - " + company.code + " - " + company?.agency?.name,
          value: company.id,
        };
      });
  }, [companies]);

  const runSnapshotReportExport = async () => {
    setExportingCsv(true);
    if (exportingCsv) {
      return;
    }
    try {
      let queryString = "";
      if (companyUser) {
        let queryParams = {
          client: companyUser,
        };
        let queryFilters = new URLSearchParams(queryParams);
        removeEmptySearchParams(queryFilters);
        queryString = queryFilters.toString();
      }
      if (companyParams) {
        let queryParams = {
          companies: companyParams,
        };
        let queryFilters = new URLSearchParams(queryParams);
        removeEmptySearchParams(queryFilters);
        queryString = queryFilters.toString();
      }
      let response = await axios.get(
        window.API + ROUTES.API.CORPORATE.REPORTS.SNAPSHOT_EXPORT + "?" + queryString,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        "csv",
        response.data,
        `spend-report.csv`,
      );
      setExportingCsv(false);
    } catch (e) {
      setExportingCsv(false);
      console.error(e);
    }
  };

  const handleCompanySelect = useCallback(e => {
    const params = e ? e.map(com => com.value) : [];
    setCompanyParams(params);
    window.localStorage.setItem("companyParams", params);
  }, []);

  return <>
    <div className="">
      {!dashboardReport ? (
        <h1 className="my-4">
          {ownReport ? `My Snapshot Report` : `Company Snapshot Report`}
        </h1>
      ) : null}
      <div
        className={`flex flex-col lg:flex-wrap lg:flex-row align-bottom p-3 mb-3 lg:mb-0 lg:items-end ${!dashboardReport ? " bg-white" : ""}`}>
        {!isFetchingSnapshotReport && (
          <div className="mb-2 xxl:mb-0 mr-4">
            <div className="flex text-xs min-h-9.5">
              <ButtonSpan
                className={classnames(
                  "inline-block flex items-center px-3 border border-grey-md border-r-0",
                  {
                    "opacity-50": dateFilter === "event_date_from",
                    "bg-brand": dateFilter !== "event_date_from",
                  },
                )}
                onClick={() => setDateFilter("created_at")}
              >
                Enquiry Date
              </ButtonSpan>
              <ButtonSpan
                className={classnames(
                  "inline-block flex items-center px-3 border border-grey-md",
                  {
                    "opacity-50": dateFilter === "created_at",
                    "bg-brand": dateFilter !== "created_at",
                  },
                )}
                onClick={() => setDateFilter("event_date_from")}
              >
                Event Date
              </ButtonSpan>
            </div>
          </div>)}
        {showFilters ? (
          <>
            {getAgenciesOptions.length > 0 ? (
              <div className="mb-2 xxl:mb-0 mr-4">
                <Select
                  name="agency"
                  label="Filter by Agency"
                  component={Select}
                  options={getAgenciesOptions}
                  wrapperClassName="mb-0"
                  input={{
                    onChange: e => setSelectedAgency(e.target.value),
                    value: selectedAgency,
                  }}
                  meta={{}}
                />
              </div>
            ) : null}
            {getCompaniesOptions.length > 0 ? (
              <div className="mb-2 xxl:mb-0 mr-4">
                <MultiSelect
                  key={"companyMultiSelect"}
                  label={"Filter by Company"}
                  cache={true}
                  cacheKey={"companyParams"}
                  options={getCompaniesOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  wrapperClassName="mb-0"
                  onChange={(e) => handleCompanySelect(e)}
                  allowSelectAll={true}
                  components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                  }}
                  style={{
                    searchBox: {
                      "border-radius": "0px",
                    },
                  }}
                />
              </div>
            ) : null}
            {companyUserOptions.length > 0 ? (
              <div className="mb-2 xxl:mb-0 mr-4">
                <Select
                  name="user"
                  label="Filter by User"
                  placeholder="Viewing All Users"
                  component={Select}
                  options={companyUserOptions}
                  wrapperClassName="mb-0"
                  input={{
                    onChange: e => setCompanyUser(e.target.value),
                    value: companyUser,
                  }}
                  meta={{}}
                />
              </div>
            ) : null}
          </>
        ) : null}

        {!isFetchingCompany && !isFetchingSnapshotReport && snapshotReport && snapshotReport.length > 0 ?
          <SnapshotReportTable data={snapshotReport} /> : null}

        {isFetchingCompany || isFetchingSnapshotReport && <Loading />}

        {!dashboardReport && !isFetchingCompany && !isFetchingSnapshotReport && snapshotReport && snapshotReport.length > 0 ? (
          <div className={"row mb-2"}>
            <div className={"col flex"}>
              <ButtonPrimary classes={"mt-4"} onClick={() => runSnapshotReportExport()}>
                {exportingCsv ? <Loading inline /> : "Export to CSV"}
              </ButtonPrimary>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  </>;
};
const mapStateToProps = state => {
  let corporateAccount = Object.entries(corporateAccountObject(state.auth)).length > 0 ? corporateAccountObject(state.auth) : state.corporateAccount?.corporateAccount;
  let companyAccount = Object.entries(companyObject(state.auth)).length > 0 ? companyObject(state.auth) : state.company?.company;
  let companies = state.companyList.companiesForEvents ? state.companyList.companiesForEvents.data : [];
  let isEdgeAdmin = isRoleEdgeAdmin(state.auth);
  if (isEdgeAdmin) {
    companies = state.companyList.companies ? state.companyList.companies : [];
  }
  return {
    ...state.snapshotReport,
    ...state.selectedAgencies,
    ...state.company,
    ...state.companyList,
    ...state.agenciesForSelect,
    ...state.agencyList,
    companies: companies || [],
    corporateAccount: corporateAccount,
    companyObject: companyAccount,
    isAgency: isAgencyUser(state.auth),
    token: token(state),
    isEdgeAdmin: isRoleEdgeAdmin(state.auth),
    user: user(state),
  };
};

const mapDispatchToProps = {
  fetchCompanies,
  fetchSnapshotReport,
  snapshotReportUnload,
  fetchCompaniesForEvents,
  fetchCorporateAccount,
  unloadCompanyList,
  unloadCorporateAccount,
  fetchCompany,
  companyUnload,
  fetchAgenciesForSelect,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotReportContainer);
