import React, { useEffect, useState } from "react";
import axios from 'axios';
import { format } from "date-fns";
import { ButtonPrimary } from "../../../../Components/Button";
import { Link } from "react-router-dom";
import { currencyFormatter, downloadButton, getSuperAgencyPill, nameFormatter } from "../../../../utils";
import ReactTooltip from "react-tooltip";
import BookedEventsDownloadButton from "../../../../Components/events/BookedEventsDownloadButton";
import moment from "moment";
import EventsListDownloadProposalConfirmationActionButtons from "./EventsListDownloadProposalConfirmationActionButtons";
import { BOOKED_EVENT_STATUS, ENQUIRY_STATUS } from "../../../../constants";
import TableWithCollapsibleRows from "../../../../Components/Table/TableWithCollapsibleRows";

const tableHeaders = [
  ":expand",
  {
    key: "event_date",
    display: "Event Date",
  },
  {
    key: "id",
    display: "ID",
    class: "min-w-64",
  },
  {
    key: "company",
    display: "Company",
  },
  {
    key: "branch",
    display: "Branch",
  },
  {
    key: "meetingHost",
    display: "Meeting Host",
  },
  {
    key: "venue",
    display: "Venue",
  },
  // {
  //   key: 'location',
  //   display: 'Location',
  // },
  {
    key: "status",
    display: "Status",
  },
  {
    key: "commission",
    display: "Commission",
  },
  {
    key: "value",
    display: "Net Event Value",
  },
  {
    key: "download",
    display: "Download",
  },
  {
    key: "view",
    display: "View",
  },
  {
    key: "manage",
    display: "Manage",
  },
];

const renderDownloadButtons = (bookedEvent, confirmedData, props) => {
  let contractFileUrl = bookedEvent.contract_file_url && typeof bookedEvent.contract_file_url !== "undefined" ? bookedEvent.contract_file_url : null;
  let delegateFileUrl = bookedEvent.delegate_file_url && typeof bookedEvent.delegate_file_url !== "undefined" ? bookedEvent.delegate_file_url : null;
  let invoiceFileUrl = bookedEvent.invoice_file_url && typeof bookedEvent.invoice_file_url !== "undefined" ? bookedEvent.invoice_file_url : null;

  let preAddUrl = (link) => {
    let testForHttp = /^((http|https|ftp):\/\/)/;

    if (link && !testForHttp.test(link)) {
      return "http://" + link;
    }
  };

  if (bookedEvent.event_status.code === BOOKED_EVENT_STATUS.DENIED || bookedEvent.event_status.code === BOOKED_EVENT_STATUS.CANCELLED) {
    return null;
  }
  return (
    <>
      <div className="flex items-center content-center">
        <div className={"w-40"}>
          {downloadButton(contractFileUrl, "fa-file-contract", "Download Contract")}
          {downloadButton(delegateFileUrl ? delegateFileUrl : preAddUrl(bookedEvent.delegate_text), "fa-users", "Download Delegate List")}
          {downloadButton(invoiceFileUrl, "fa-hotel", "Download Client/Venue Invoice")}
          {props.isCompanyAdmin || props.isCompanyUser || (props.agency && !props.agency.can_manage_invoicing) ? null :
            <BookedEventsDownloadButton
              type={"invoice"}
              text={bookedEvent.event_status.code >= BOOKED_EVENT_STATUS.COMPLETED ? "Download Invoice" : "Generate Invoice"}
              event={bookedEvent}
              style={"link"}
              disabled={bookedEvent.event_status.code < BOOKED_EVENT_STATUS.COMPLETED}
            />
          }
          {props.isCompanyAdmin || props.isCompanyUser  || (props.agency && !props.agency.can_manage_invoicing) ? null :
            <BookedEventsDownloadButton
              type={"xero"}
              text={"Export Event For Xero"}
              event={bookedEvent} style={"link"}
              disabled={bookedEvent.event_status.code < BOOKED_EVENT_STATUS.COMPLETED}
              isRed={!!bookedEvent.exported_to_xero_date}
            />
          }
          <ReactTooltip />
        </div>
      </div>
    </>
  );
};

const renderButtons = (bookedEvent, confirmedData, canManageEventPreData, canManageEventCompletion, isCompanyAccount, canChaseEvent, token, openModal, closeModal) => {
  return (
    <>
      {
        !(isCompanyAccount && [BOOKED_EVENT_STATUS.DENIED, BOOKED_EVENT_STATUS.CANCELLED, BOOKED_EVENT_STATUS.PENDING_INVOICE, BOOKED_EVENT_STATUS.COMPLETED].includes(bookedEvent.data.event_status.code)) &&
        (canManageEventPreData || canManageEventCompletion)
          // &&
          // ( isCompanyAccount &&  bookedEvent.data.event_status.code < BOOKED_EVENT_STATUS.COMPLETED || !isCompanyAccount && bookedEvent.data.event_status.code !== BOOKED_EVENT_STATUS.CANCELLED)
          ?
          <ButtonPrimary
            classes={"mx-2 w-30 text-xs"}
            Element={Link}
            to={"/admin/events/" + bookedEvent.data.id}
          >
            {isCompanyAccount && bookedEvent.data.event_status.code === BOOKED_EVENT_STATUS.CONFIRMED ? "Delegates" : "Manage"}
          </ButtonPrimary>
          : null}
      {bookedEvent.data.enquiry.suppress_notifications !== 1 && bookedEvent.data.enquiry.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED && bookedEvent.data.event_status && bookedEvent.data.event_status.code >= BOOKED_EVENT_STATUS.CONFIRMED && bookedEvent.data.event_status.code < BOOKED_EVENT_STATUS.COMPLETED && bookedEvent.data.contract_file_url == null && canChaseEvent && (
        <>
          <ButtonPrimary
            classes={"mr-2 w-30 text-xs"}
            Element={Link}
            to={"#"}
            onClick={() => {
              openModal(bookedEvent.data, true);
            }}
            data-tip
            data-for={"lastChasedDate" + bookedEvent.data.id}
          >
            Chase Contract<br />
          </ButtonPrimary>
          {bookedEvent.data.contract_last_chased_date ?
            <ReactTooltip id={"lastChasedDate" + bookedEvent.data.id}>
              Last Chased At: {moment(bookedEvent.data.contract_last_chased_date).format("DD/MM/YYYY HH:mm:ss")}
            </ReactTooltip> : null}
        </>
      )}
      {bookedEvent.data.enquiry.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED && bookedEvent.data.event_status && bookedEvent.data.event_status.code === BOOKED_EVENT_STATUS.PENDING_INVOICE && bookedEvent.data.invoice_file_url == null && canChaseEvent && (
        <>
          <ButtonPrimary
            classes={"mr-2 w-30 text-xs"}
            Element={Link}
            to={"#"}
            onClick={() => {
              openModal(bookedEvent.data, false);
            }}
            data-tip
            data-for={"lastInvoicedDate" + bookedEvent.data.id}
          >
            Chase Invoice<br />
          </ButtonPrimary>
          {bookedEvent.data.invoice_last_chased_date ?
            <ReactTooltip id={"lastInvoicedDate" + bookedEvent.data.id}>
              Last Chased At: {moment(bookedEvent.data.invoice_last_chased_date).format("DD/MM/YYYY HH:mm:ss")}
            </ReactTooltip> : null}
        </>
      )}
    </>
  );
};

const EventsListTable = (props) => {
  const { bookedEvents = [], isSuperAgent } = props;
  const [tableData, setTableData] = useState([]);
  const [expandedRow, setExpandedRow] = useState();
  const [shouldShowComponent, setShouldShowComponent] = useState(false);

  const getLinkedEvents = async id => {
    if (typeof expandedRow === 'object' && expandedRow.hasOwnProperty(id)) {
      setExpandedRow()
    } else {
      setExpandedRow()
      await axios.get(`${window.API}/event/${id}/linked-events`, {
        headers: {
          Authorization: `Bearer ${props.token}`
        }
      })
        .then(({ data }) => {
          setExpandedRow(() => ({
            [id]: data
          }));
        })
        .catch(error => {
        setExpandedRow()
        console.error(error)
      })
    }
  }

  useEffect(() => {
    setTableData(getBookedEventsData());
  }, []);

  let filteredHeaders = tableHeaders;
  if (!props.canManageEventPreData && !props.canManageEventCompletion) {
    filteredHeaders = filteredHeaders.filter((header) => header.key !== "manage");
  }
  if (!props.canViewCommissionValue) {
    filteredHeaders = filteredHeaders.filter((header) => header.key !== "commission");
  }
  if (props.isCompanyAccount) {
    filteredHeaders = filteredHeaders.filter((header) => header.key !== "company");
  } else {
    filteredHeaders = filteredHeaders.filter((header) => header.key !== "meetingHost");
  }

  const addInformationRow = (bookedEvent, id) => {
    if (typeof expandedRow === "object" && expandedRow.hasOwnProperty(id)) {
      setExpandedRow();
    } else {
      setExpandedRow();
      setExpandedRow((prevData) => ({
        ...prevData,
        [id]: bookedEvent.data,
      }));
      setShouldShowComponent(true);
    }
  };

  const getMappedDataForExpandedRow = bookedEvent => {
    let confirmedData = bookedEvent.enquiry_venue_datas;
    let enquiry = bookedEvent.enquiry;
    let projected_commission_value = bookedEvent.enquiry_venue_datas && bookedEvent.enquiry_venue_datas.proposal_data.commission_items.find(item => item.step_id === 9) ? bookedEvent.enquiry_venue_datas.proposal_data.commission_items.find(item => item.step_id === 9).total_commission : null;
    let estimated_commission_value_net = bookedEvent.estimated_commission_value_net ? bookedEvent.estimated_commission_value_net : projected_commission_value;
    let netTotal = bookedEvent.enquiry_venue_datas && bookedEvent.enquiry_venue_datas.proposal_data.summary && bookedEvent.enquiry_venue_datas.proposal_data.summary.summary_items.find(item => item.step_id === 9) ?
      bookedEvent.enquiry_venue_datas.proposal_data.summary.summary_items.find(item => item.step_id === 9).total_exc_vat : null;
    return {
      event_id: bookedEvent.id,
      event_date: (
          <span className="text-xs">
            {`${format(bookedEvent.event_date_from, "DD/MM/YYYY")}${bookedEvent.event_date_to ? " - " + format(bookedEvent.event_date_to, "DD/MM/YYYY") : ""}`}
          </span>
      ),
      id: (
        <>
          <span className="font-bold text-sm block">
            {enquiry.ref_id ? enquiry.ref_id : (enquiry && enquiry.client_company) ? enquiry.client_company.code + enquiry.id : ""}
          </span>
          <span className="mt-2 text-sm block">{enquiry.event_name}</span>
          {isSuperAgent ? (
              <>
                {/*<strong>{enquiry.client_company ? enquiry.client_company.name : enquiry.minimal_user.email}</strong> - {enquiry.client_name}*/}
              </>
            ) :
            <>
              <span className={"text-xs"}>
                {enquiry.client_company ? enquiry.client_company.name : enquiry.minimal_user.email}
              </span><br />
            </>
          }
          <div className={"flex"}>
            {isSuperAgent ? getSuperAgencyPill(bookedEvent.company) : null}
            {enquiry.is_highly_confidential ? (
              <span className="inline-block bg-red text-white p-1 mt-2 text-xs mr-1">Confidential</span>
            ) : null}
            {enquiry.rfp_xpress_service && props.isAgency ? (
              <span className="inline-block bg-green text-white p-1 mt-2 text-xs mr-1">Xpress/BC</span>
            ) : null}
            {isSuperAgent ? (
              enquiry.corporate_specific_data && enquiry.corporate_specific_data.booking_desk ? (
                <span
                  className="inline-block bg-blue text-white p-1 mt-2 text-xs mr-1">{enquiry.corporate_specific_data.booking_desk.name}</span>
              ) : enquiry.corporate_specific_data && enquiry.corporate_specific_data.agency_booking_desk ? (
                <span
                  className="inline-block bg-blue text-white p-1 mt-2 text-xs mr-1">{enquiry.corporate_specific_data.agency_booking_desk.name}</span>
              ) : null
            ) : null}
            {bookedEvent.department && (
              <span
                className="inline-block bg-grey-darker text-white p-1 mt-2 text-xs mr-1">{bookedEvent.department.name}</span>
            )}
          </div>
        </>
      ),
      company: (
        <span>
          {bookedEvent.company ? bookedEvent.company.name : "N/A"}
        </span>
      ),
      branch: (
        <span>
          {bookedEvent.company_branch ? bookedEvent.company_branch.name : "N/A"}
        </span>
      ),
      meetingHost: (
        <span>
          {bookedEvent.meeting_host ? nameFormatter(bookedEvent.meeting_host) : "N/A"}
        </span>
      ),
      venue: (
        <span>
          {confirmedData && confirmedData.minimal_venue ?
            <a className={"text-black"}
               href={`/venue/${confirmedData.minimal_venue.slug}`}>{confirmedData.minimal_venue.name}</a> : "Venue not chosen"}
          <span className="text-xs"><br />
            {confirmedData && confirmedData.minimal_venue ? `${confirmedData.minimal_venue.town}` : ""}
          </span>
        </span>
      ),
      status: (
        <>
          {props.isCompanyAccount && bookedEvent.event_status && parseInt(bookedEvent.event_status.code) === BOOKED_EVENT_STATUS.PENDING_INVOICE ?
            <span className="p-1 bg-blue-light text-xs">
              Completed
            </span>
            :
            <span className="p-1 bg-blue-light text-xs">
              {bookedEvent.event_status && bookedEvent.event_status.name}
            </span>
          }
        </>
      ),
      commission: (
        <span>
              {bookedEvent.total_commission_value_net ? (
                <>
                  {currencyFormatter("GBP").format(bookedEvent.total_commission_value_net)}
                </>
              ) : (
                estimated_commission_value_net ?
                  <>
                    Est: {currencyFormatter("GBP").format(estimated_commission_value_net)}
                  </>
                  : projected_commission_value ?
                    <>
                      Est: {currencyFormatter("GBP").format(projected_commission_value)}
                    </>
                    :
                    <>
                      TBD
                    </>
              )}
            </span>
      ),
      value: (
        <span>
              <>
                {currencyFormatter("GBP").format(netTotal)}
              </>
            </span>
      ),
      view: (
        <div className="flex">
          <EventsListDownloadProposalConfirmationActionButtons
            bookedEvent={bookedEvent}
            confirmedData={confirmedData}
            canManageEventPreData={props.canManageEventPreData}
            canManageEventCompletion={props.canManageEventCompletion}
            isCompanyAccount={(props.isCompanyAdmin || props.isCompanyUser)}
            canChaseEvent={props.canChaseEvent}
            token={props.token}
            openModal={props.openModal}
            closeModal={props.closeModal}
          />
        </div>
      ),
      // manage: (
      //   <div className="flex">
      //     {renderButtons(bookedEvent, confirmedData, props.canManageEventPreData, props.canManageEventCompletion, (props.isCompanyAdmin || props.isCompanyUser), props.canChaseEvent, props.token, props.openModal, props.closeModal)}
      //   </div>
      // ),
      download: (
        <div className="flex">
          {renderDownloadButtons(bookedEvent, confirmedData, props)}
        </div>
      ),
    }
  }

  const getBookedEventsData = () => {
    return bookedEvents.map(bookedEvent => {
      if (bookedEvent.data) {
        let confirmedData = bookedEvent.data.enquiry_venue_datas;
        let enquiry = bookedEvent.data.enquiry;
        let projected_commission_value = bookedEvent.data.enquiry_venue_datas && bookedEvent.data.enquiry_venue_datas.proposal_data.commission_items.find(item => item.step_id === 9) ? bookedEvent.data.enquiry_venue_datas.proposal_data.commission_items.find(item => item.step_id === 9).total_commission : null;
        let estimated_commission_value_net = bookedEvent.data.estimated_commission_value_net ? bookedEvent.data.estimated_commission_value_net : projected_commission_value;
        let netTotal = bookedEvent.data.enquiry_venue_datas && bookedEvent.data.enquiry_venue_datas.proposal_data.summary && bookedEvent.data.enquiry_venue_datas.proposal_data.summary.summary_items.find(item => item.step_id === 9) ?
          bookedEvent.data.enquiry_venue_datas.proposal_data.summary.summary_items.find(item => item.step_id === 9).total_exc_vat : null;
        let expandMethod = () => {};
        let iconClasses = 'fas fa-plus'
        if (bookedEvent.data.enquiry.linked_enquiries && bookedEvent.data.enquiry.linked_enquiries.length > 0) {
          if (isSuperAgent) {
            expandMethod = () => <span
              onClick={() => getLinkedEvents(bookedEvent.data.id)}
              className="pointer">
              <i className={typeof expandedRow === 'object' && expandedRow.hasOwnProperty(bookedEvent.data.id) ? 'fas fa-minus' : iconClasses} />
            </span>
          }
        }

        if(!netTotal) {
          netTotal = bookedEvent.data.enquiry ?  bookedEvent.data.enquiry.estimated_value : null;
        }
        return {
          ":expand": expandMethod(),
          event_id: bookedEvent.data.id,
          event_date: () => (
            <span className="text-xs">
              {`${format(bookedEvent.data.event_date_from, "DD/MM/YYYY")}${bookedEvent.data.event_date_to ? " - " + format(bookedEvent.data.event_date_to, "DD/MM/YYYY") : ""}`}
              {/*<br />*/}
              {/*<br />*/}
              {/*<i className="text-blue fa fa-info-circle pointer" title="Quick info"*/}
              {/*   onClick={() => addInformationRow(bookedEvent, bookedEvent.data.id)} />*/}
            </span>
          ),
          id: () => (
            <>
              <span className="font-bold text-sm block">
                {enquiry.ref_id ? enquiry.ref_id : (enquiry && enquiry.client_company) ? enquiry.client_company.code + enquiry.id : ""}
              </span>
              <span className="mt-2 text-sm block">{enquiry.event_name}</span>
              {isSuperAgent ? (
                  <>
                    <strong>{enquiry.client_company ? enquiry.client_company.name : enquiry.minimal_user.email}</strong> - {enquiry.client_name}</>
                ) :
                <>
                  <span className={"text-xs"}>
                    {enquiry.client_company ? enquiry.client_company.name : enquiry.minimal_user.email}
                  </span><br />
                </>
              }
              <div className={"flex"}>
                {isSuperAgent ? getSuperAgencyPill(bookedEvent.data.company) : null}
                {enquiry.is_highly_confidential ? (
                  <span className="inline-block bg-red text-white p-1 mt-2 text-xs mr-1">Confidential</span>
                ) : null}
                {enquiry.rfp_xpress_service && props.isAgency ? (
                  <span className="inline-block bg-green text-white p-1 mt-2 text-xs mr-1">Xpress/BC</span>
                ) : null}
                {isSuperAgent ? (
                  enquiry.corporate_specific_data && enquiry.corporate_specific_data.booking_desk ? (
                    <span
                      className="inline-block bg-blue text-white p-1 mt-2 text-xs mr-1">{enquiry.corporate_specific_data.booking_desk.name}</span>
                  ) : enquiry.corporate_specific_data && enquiry.corporate_specific_data.agency_booking_desk ? (
                    <span
                      className="inline-block bg-blue text-white p-1 mt-2 text-xs mr-1">{enquiry.corporate_specific_data.agency_booking_desk.name}</span>
                  ) : null
                ) : null}
                {bookedEvent.data.department && (
                  <span
                    className="inline-block bg-grey-darker text-white p-1 mt-2 text-xs mr-1">{bookedEvent.data.department.name}</span>
                )}
              </div>
            </>
          ),
          company: () => (
            <span>
              {bookedEvent.data.company ? bookedEvent.data.company.name : "N/A"}
            </span>
          ),
          branch: () => (
            <span>
              {bookedEvent.data.company_branch ? bookedEvent.data.company_branch.name : "N/A"}
            </span>
          ),
          meetingHost: () => (
            <span>
              {bookedEvent.data.meeting_host ? nameFormatter(bookedEvent.data.meeting_host) : "N/A"}
            </span>
          ),
          venue: () => (
            <span>
              {confirmedData && confirmedData.minimal_venue ?
                <a className={"text-black"}
                   href={`/venue/${confirmedData.minimal_venue.slug}`}>{confirmedData.minimal_venue.name}</a> : "Venue not chosen"}
              <span className="text-xs"><br />
                {confirmedData && confirmedData.minimal_venue ? `${confirmedData.minimal_venue.town}` : ""}
              </span>
            </span>
          ),
          status: () => (
            <>
              {props.isCompanyAccount && bookedEvent.data.event_status && parseInt(bookedEvent.data.event_status.code) === BOOKED_EVENT_STATUS.PENDING_INVOICE ?
                <span className="p-1 bg-blue-light text-xs">
                  Completed
                </span>
                :
                <span className="p-1 bg-blue-light text-xs">
                  {bookedEvent.data.event_status && bookedEvent.data.event_status.name}
                </span>
              }
            </>
          ),
          commission: () => (
            <span>
              {bookedEvent.data.total_commission_value_net ? (
                <>
                  {currencyFormatter("GBP").format(bookedEvent.data.total_commission_value_net)}
                </>
              ) : (
                estimated_commission_value_net ?
                  <>
                    Est: {currencyFormatter("GBP").format(estimated_commission_value_net)}
                  </>
                  : projected_commission_value ?
                    <>
                      Est: {currencyFormatter("GBP").format(projected_commission_value)}
                    </>
                    :
                    <>
                      TBD
                    </>
              )}
            </span>
          ),
          value: () => (
            <span>
              <>
                {currencyFormatter("GBP").format(netTotal)}
              </>
            </span>
          ),
          view: (
            <div className="flex">
              <EventsListDownloadProposalConfirmationActionButtons
                bookedEvent={bookedEvent.data}
                confirmedData={confirmedData}
                canManageEventPreData={props.canManageEventPreData}
                canManageEventCompletion={props.canManageEventCompletion}
                isCompanyAccount={(props.isCompanyAdmin || props.isCompanyUser)}
                canChaseEvent={props.canChaseEvent}
                token={props.token}
                openModal={props.openModal}
                closeModal={props.closeModal}
              />
            </div>
          ),
          manage: (
            <div className="flex">
              {renderButtons(bookedEvent, confirmedData, props.canManageEventPreData, props.canManageEventCompletion, (props.isCompanyAdmin || props.isCompanyUser), props.canChaseEvent, props.token, props.openModal, props.closeModal)}
            </div>
          ),
          download: (
            <div className="flex">
              {renderDownloadButtons(bookedEvent.data, confirmedData, props)}
            </div>
          ),
        };
      } else {
        return {};
      }
    });
  };

  return (
    <TableWithCollapsibleRows
      admin
      tableIdKey={"event_id"}
      headers={filteredHeaders}
      data={tableData}
      expandedRow={expandedRow}
      mappingMethod={getMappedDataForExpandedRow}
    />
  );
};

export default EventsListTable;
