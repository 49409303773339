import React, { useEffect, useState } from "react";
import DropzoneArea from "../../../../../Components/Form/File/DropzoneArea";
import { Field, getFormValues } from "redux-form";
import { ButtonPrimary, ButtonSpan } from "../../../../../Components/Button";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { Input, Select } from "../../../../../ui";
import { required } from "../../../../../modules/validation";
import { fetchAgencyNominalCodes, fetchVatRates } from "../../../../../redux/actions";
import { token } from "../../../../../modules/auth";
import Toggle from "../../../../../ui/Toggle";
import Warning from "../../../../../Components/Warning";
import { downloadEdgeDocument } from "../../../../../services/downloadEdgeDocument";
import { checkHasGBPFinancialData, getCurrencySymbol } from "../../../../../utils";
import * as AGENCY_ROUTES from "../../../../../Router/corporate_routes";
import { Link } from "react-router-dom";

const v = {
  price_vat_rate_id: required(),
};

const mapStateToProps = state => {
  return {
    vatRates: state.vatRate.vatRates,
    nominalCodes: state.nominalCodes.nominalCodes,
    token: token(state),
  };
};

const mapDispatchToProps = {
  fetchVatRates,
  fetchAgencyNominalCodes,
};

/**
 * Changed in AE-28
 * hard coded codes for now see EDGECORP-166
 */
// const nominalCodes = [
//     {
//         value: '4000',
//         text: '4000 - Commission',
//         number: '4000',
//     },
//     {
//         value: '4005',
//         text: '4005 - Eu Commission',
//         number: '4005',
//     },
//     {
//         value: '4006',
//         text: '4006 - Other international commission',
//         number: '4006',
//     }
// ]

let CompletedEventInformation = (
  {
    setInvoiceUploadedDocument,
    invoiceUploadedDocument,
    invoiceDocument,
    deleteFile,
    event,
    fetchNominalCodes,
    vatRates,
    token,
    openFinalModal,
    submitting,
    change,
    fetchAgencyNominalCodes,
    nominalCodes,
    agencyFinancials,
  }) => {
  const values = useSelector(getFormValues("eventsForm")) || {};
  const [requiresVat, setRequiresVat] = useState(false);
  let agencyId = event?.agency_id || event?.company?.agency_id;


  useEffect(() => {
    fetchAgencyNominalCodes(agencyId, token);
  }, []);

  const getNominalCodesOptions = () => {
    let options = [];
    if (nominalCodes) {
      nominalCodes.map(nominalCode => {
        options.push({
          value: nominalCode.code,
          text: nominalCode.code + " - " + nominalCode.description,
          number: nominalCode.code,
        });
      });
    }
    return options;
  };

  let uploadInvoiceDocument = (file) => {
    setInvoiceUploadedDocument(file);
  };

  let _deleteFile = (uploadDocument, loadedDocument) => {

    if (typeof uploadDocument !== "undefined" && uploadDocument && uploadDocument.length > 0) {
      setInvoiceUploadedDocument(null);
    } else if (typeof loadedDocument[0] !== "undefined") {

      return deleteFile(loadedDocument[0].id);
    }
  };
  let fetchFileName = (uploadDocument) => {
    if (uploadDocument && typeof uploadDocument !== "undefined" && typeof uploadDocument[0] !== "undefined") {
      return uploadDocument[0].name;
    }

    return null;
  };
  let currency = event.currency || (event.enquiry_venue_datas && event.enquiry_venue_datas.currency);
  return (
    <React.Fragment>
      <div className={"row px-3 pt-5 pb-5 border-b border-grey"}>
        <div className="col col-w-full flex flex-col">
          <h3 className="mb-2">Final Invoice from Venue to Client</h3>
          {(invoiceUploadedDocument) ? (
            <div className={"flex flex-row"}>
              <p>{fetchFileName(invoiceUploadedDocument)}</p>
              <React.Fragment>
                <ButtonSpan
                  className="pointer text-warning"
                  onClick={() => setInvoiceUploadedDocument(null)}
                >
                  <i className="ml-3 fas fa-trash-alt" />
                </ButtonSpan>
              </React.Fragment>

            </div>
          ) : <DropzoneArea
            type="document_plus"
            minWidth={"200px"}
            minHeight={"100px"}
            onDrop={uploadInvoiceDocument}
            uploading={false}
          />}

          {typeof invoiceDocument !== "undefined" && invoiceDocument.length > 0 && typeof event.invoice_uploader !== "undefined" && event.invoice_uploader !== null ?
            <div>
              <p>Uploaded
                By: {event.invoice_uploader?.first_name + " " + event.invoice_uploader?.surname} ({event.invoice_uploader?.email})</p>
              <p>Uploaded At: {moment(event.invoice_upload_date).format("DD/MM/YYYY hh:mm:ss")}</p>
              <div className={"flex flex-row"}>
                <a
                  className={"font-bold"}
                  style={{ cursor: "pointer" }}
                  onClick={() => event && event.invoice_file && typeof event.invoice_file[0] !== "undefined" ?
                    downloadEdgeDocument(event.invoice_file[0].id, event.invoice_file[0].name, token) : null
                  }>
                  Download Invoice
                </a>
                <ButtonSpan
                  className="pointer text-warning"
                  onClick={() => _deleteFile(invoiceUploadedDocument, invoiceDocument)}
                >
                  <i className="ml-3 fas fa-trash-alt" />
                </ButtonSpan>
              </div>
            </div>
            : null}
        </div>
      </div>
      <div className={"row px-3 pt-5 pb-5 border-b border-grey "}>
        <div className="col col-w-full flex flex-col">
          <h3 className="mb-2">Event Final Invoicing</h3>
          <div className={"row"}>
            <div className="col col-w-full md:col-w-1/2 flex flex-col ">
              <Field
                name="attendee_count"
                component={Input}
                label={"Final Numbers Attending"}
              />
            </div>

            <div className="col col-w-full md:col-w-1/2 flex flex-col ">
              <Field
                name="invoice_amount_net"
                component={Input}
                type="hidden"
                wrapperClassName={"hidden"}
                // label={'Final Invoice Net Value (£)'}
              />
              <Field
                name="invoice_amount_net_src_cur"
                component={Input}
                type="hidden"
                wrapperClassName={"hidden"}
                // label={'Final Invoice Net Value (£)'}
              />

              <Field
                name="invoice_amount_vat"
                component={Input}
                type="hidden"
                wrapperClassName={"hidden"}
                // label={'Final Invoice VAT Value (£)'}
              />

              <Field
                name="invoice_amount_vat_src_cur"
                component={Input}
                type="hidden"
                wrapperClassName={"hidden"}
                // label={'Final Invoice VAT Value (£)'}
              />
              {/* End hidden fields */}
            </div>
            <div className="col col-w-full md:col-w-1/2 flex flex-col ">
              <Field
                name="invoice_amount_gross"
                component={Input}
                label={"Final Invoice Total Value (£)"}
                readOnly={true}
                additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}
              />
              <Field
                name="estimated_commission_value_net"
                component={Input}
                label={"Projected Commission (£)"}
              />

            </div>
            <div className="col col-w-full md:col-w-1/2 flex flex-col ">
              {currency && currency.currency_code && currency.currency_code !== "GBP" ?
                <Field
                  name="invoice_amount_gross_src_cur"
                  component={Input}
                  label={`Final Invoice Total Value (${getCurrencySymbol({ currency_code: event.currency ? event.currency.currency_code : event.enquiry_venue_datas.currency.currency_code })})`}
                  readOnly={true}
                  additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}
                />
                : null}

              {currency && currency.currency_code && currency.currency_code !== "GBP" ?
                <Field
                  name="estimated_commission_value_net_src_cur"
                  component={Input}
                  label={`Projected Commission (${getCurrencySymbol({ currency_code: event.currency ? event.currency.currency_code : event.enquiry_venue_datas.currency.currency_code })})`}
                  readOnly={true}
                  additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}
                />
                : null}

            </div>
          </div>
        </div>
      </div>
      <div className={"row px-3 pt-5 pb-5 "}>
        <div className="col col-w-full flex  flex-col ">
          <h3 className={"mb-2"}>Final Agent Commission</h3>

          <div className={"row"}>
            {nominalCodes && nominalCodes.length > 0 ?
              <div className={"col lg:col-w-1/3"}>
                <Field
                  wrapperClassName="mb-5 mr-5 max-w-full"
                  name={`total_commission_xero_code`}
                  label="Xero export nominal code"
                  component={Select}
                  options={getNominalCodesOptions()}
                />
              </div> : null
            }
            <div className={"col col-w-1/2 lg:col-w-1/3"}>

              <Field
                wrapperClassName="mb-5 mr-5 max-w-full"
                name="total_commission_includes_vat"
                label="Apply VAT/Tax On Commission value"
                component={Toggle}
              />

            </div>

            {Boolean(values["total_commission_includes_vat"]) ?
              <div className={"col col-w-1/2 lg:col-w-1/3"}>
                {vatRates.length > 0 ?
                  <Field
                    wrapperClassName="mb-5 mr-5 max-w-full"
                    name={`total_commission_vat_rate_id`}
                    label="VAT Rate"
                    component={Select}
                    options={vatRates && vatRates.map(vat_rate => ({
                      value: vat_rate.id,
                      text: vat_rate.rate + "%",
                      number: vat_rate.rate,
                    }))}
                    onChange={() => setRequiresVat(false)}
                    validate={v.price_vat_rate_id}
                  />
                  : null}
                {requiresVat ? <span>Must select a VAT if VAT required</span> : null}
              </div>
              :
              <></>
            }

          </div>
          <div className={"row"}>
            <div className="col md:col-w-1/3">
              {
                event.total_commission_value_net === null ? <></> :
                  <>
                    <Field
                      name="total_commission_value_net"
                      component={Input}
                      readOnly={true}
                      hidden={true}
                      additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}
                      label={"Commission Net (£)"}
                    />
                    {currency && !event.total_commission_value_net ?
                      <Field
                        name="total_commission_value_net_src_cur"
                        component={Input}
                        readOnly={true}
                        hidden={true}
                        additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}
                        label={`Commission Net (${getCurrencySymbol({ currency_code: event.currency ? event.currency.currency_code : event.enquiry_venue_datas.currency.currency_code })})`}
                      /> : null}
                  </>
              }
            </div>
            <div className="col md:col-w-1/3">
              {
                event.total_commission_value_net === null ? <></> :
                  <>
                    <Field
                      name="total_commission_value_vat"
                      component={Input}
                      readOnly={true}
                      hidden={event.enquiry_venue_datas.currency}
                      // wrapperClassName={'hidden'}
                      additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}
                      label={"Commission VAT (£)"}
                    />
                    {currency && !event.total_commission_value_net ?
                      <Field
                        name="total_commission_value_vat_src_cur"
                        component={Input}
                        additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}

                        label={`Commission VAT (${getCurrencySymbol({ currency_code: event.currency ? event.currency.currency_code : event.enquiry_venue_datas.currency.currency_code })})`}
                        readOnly={true}
                      />
                      : null}
                  </>
              }
            </div>
            <div className="col md:col-w-1/3">
              {event.total_commission_value_net === null || event.total_commission_value_vat === null ? <></> :
                <>
                  <Field
                    name="total_commission_value_gross"
                    component={Input}
                    readOnly={true}
                    hidden={event.enquiry_venue_datas.currency}
                    additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}

                    // wrapperClassName={'hidden'}
                    label={"Commission Gross (£)"}
                  />
                  {currency && !event.total_commission_value_net ?
                    <Field
                      name="total_commission_value_gross_src_cur"
                      component={Input}
                      additionalReadonlyClass={" text-sm py-1 h-auto bg-light-grey"}
                      label={`Commission Gross (${getCurrencySymbol({ currency_code: event.currency ? event.currency.currency_code : event.enquiry_venue_datas.currency.currency_code })})`}
                      readOnly={true}
                    />
                    : null}
                </>
              }
            </div>
          </div>
          <div className={"row"}>
            {!event?.invoice_financials || checkHasGBPFinancialData(agencyFinancials) ? (
              event.total_commission_value_net ? null :
                <div className={"col"}>
                  <ButtonPrimary classes={`mr-2 `} onClick={() => {
                    if (Boolean(values["total_commission_includes_vat"])) {
                      if (!values["total_commission_vat_rate_id"]) {
                        setRequiresVat(true);
                      } else {
                        openFinalModal();
                      }
                    } else {
                      openFinalModal();
                    }
                  }}>
                    {"Calculate Commission"}
                  </ButtonPrimary>
                </div>
            ) : (
              <div className="bg-warning px-3 py-6 my-5 block font-bold text-white">
                <p className={"mb-0"}>
                  <i className="fas text-2lg fa-exclamation-triangle mr-3" />
                  Financials details are missing for this currency or GBP. Please go and add them
                  <Link to={AGENCY_ROUTES.FRONTEND.AGENCY.FINANCIALS.EDIT.replace(":agencyId", agencyId)}>
                    {" "}here</Link> before continuing.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {Boolean(values["total_commission_includes_vat"]) !== Boolean(event.total_commission_includes_vat) ?
        <Warning><p className="text-center text-base font-bold mb-0">You will need to recalculate final commission
          values after saving this change</p></Warning> : null}
    </React.Fragment>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedEventInformation);
