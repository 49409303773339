import React, { useMemo, useCallback, useEffect, useState } from "react";
import { Date, Select } from "../../../ui";
import { format } from "date-fns";
import { ButtonGrey, ButtonPrimary } from "../../Button";
import { LiveSearchSimple } from "../../Form/LiveSearch/LiveSearchSimple";
import { nameFormatter } from "../../../utils";
import moment from "moment";
import { Input } from "../../Form";
import { MultiValue, Option, ValueContainer } from "./MultiValueComponents";
import MultiSelect from "../../../ui/MultiSelect";
import Loading from "../../Loading";

const defaultDateFrom = moment().subtract(1, "month").format("YYYY-MM-DD 00:00:00");
const defaultDateTo = moment().format("YYYY-MM-DD 00:00:00");

const ReportFilters = ({
                         corporateAccount,
                         getCorporateAccount = () => {},
                         runReport = () => {},
                         resetReport = () => {},
                         total = 0,
                         showExport = true,
                         getBookingDesks = () => {},
                         corporateDepartments = () => {},
                         getVenuesForHotelGroup = () => {},
                         venues,
                         companies = [],
                         companyUsers,
                         getCompany,
                         showFilters = {},
                         runReportExport = () => {},
                         error,
                         advancedFilters = false,
                         isMultiFilters = false,
                         isSuperAgent = false,
                         isAgent = false,
                         shouldDisableSubmit,
                         isEdgeAdmin,
                         token,
                         fetchCompanies,
                         agenciesForSelect,
                         selectedAgency,
                         setSelectedAgency
                       }) => {
  const defaultDateTo = moment().format("YYYY-MM-DD 00:00:00");
  const [companyMultiSelectKey, setCompanyMultiSelectKey] = useState(`companyMultiSelect`);
  const [departmentMultiSelectKey, setDepartmentMultiSelectKey] = useState(`departmentMultiSelect`);
  const [dateTo, setDateTo] = useState(defaultDateTo);
  const [dateFrom, setDateFrom] = useState(defaultDateFrom);
  const [department, setDepartment] = useState("");
  const [hotelGroup, setHotelGroup] = useState("");
  const [venue, setVenue] = useState("");
  const [queryParams, setQueryParams] = useState({});
  const [shouldClearLiveSearch, setShouldClearLiveSearch] = useState(false);
  const [bookingDesk, setBookingDesk] = useState("");
  const [bookingDesks, setBookingDesks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [companyUser, setCompanyUser] = useState("");
  const [company, setCompany] = useState("");
  const [companyUserOptions, setCompanyUserOptions] = useState([]);
  const [independentVenues, setIndependentVenues] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [country, setCountry] = useState("");
  const [town, setTown] = useState("");
  const [county, setCounty] = useState("");
  const [bookingAgent, setBookingAgent] = useState("");
  let customFieldFilterClass = "mb-2 xxl:mb-0 w-full lg:w-48 mr-2";
  let companyParams;
  let departmentParams;

  useEffect(() => {
    let options = [];
    companyUsers && companyUsers.length > 0 && companyUsers.sort((a, b) => a.first_name.localeCompare(b.first_name)).map(user => {
      if (user.corporate_department_id == department) {
        options.push({
          text: nameFormatter(user),
          value: user.id,
        });
      }
    });
    options.sort((a, b) => a.value.localeCompare(b.value));
    setCompanyUserOptions(options);
  }, [department]);

  useEffect(() => {
    if (shouldClearLiveSearch) {
      setShouldClearLiveSearch(false);
    }
  }, [shouldClearLiveSearch]);

  useEffect(() => {
    if (company) {
      companies.map(com => {
        if (Number(com.id) === Number(company)) {
          getCompany(com.id);
          getCorporateAccount(com.corporate_account_id);
        }
      });
    }
    companyParams = window.localStorage.getItem("companyParams");
    departmentParams = window.localStorage.getItem("departmentParams");
  }, [company]);

  useEffect(() => {
    let options = [];
    companyUsers && companyUsers.length > 0 && companyUsers.sort((a, b) => a.first_name.localeCompare(b.first_name)).map(user => {
      options.push({
        text: user.first_name + " " + user.surname,
        value: user.id,
      });
    });
    setCompanyUserOptions(options);
    setBookingDesks(getBookingDesks());
    return () => {
      window.localStorage.removeItem("departmentParams");
      window.localStorage.removeItem("companyParams");
    };
  }, []);

  useEffect(() => {
    if (independentVenues) {
      setHotelGroup(null);
    }
  }, [independentVenues]);

  useEffect(() => {
    setDepartments(corporateDepartments);
  }, [corporateDepartments]);

  useEffect(() => {
    if (hotelGroup !== "") {
      getVenuesForHotelGroup(hotelGroup);
    }
  }, [hotelGroup]);

  useEffect(() => {
    let dateRange = (dateTo && dateFrom) ? dateFrom + "," + dateTo : "";
    setQueryParams({
      "dateRange": dateRange,
      "dateFrom": dateRange === "" ? dateFrom : "",
      "dateTo": dateRange === "" ? dateTo : "",
      "department": department,
      "client": companyUser,
      "hotelGroup": hotelGroup,
      "country": country,
      "county": county,
      "town": town,
      "bookingAgent": bookingAgent,
      "venue": venue,
      "company": company,
      "page": 1,
    });
  }, [dateTo, dateFrom, department, hotelGroup, venue, companyUser, country, town, county, bookingAgent, company]);

  const resetFilters = () => {
    setDateTo(defaultDateTo);
    setDateFrom(defaultDateFrom);
    setHotelGroup("");
    setDepartment("");
    setSelectedAgency();
    // setDepartments([]);
    setCompanyUser("");
    setCompany("");
    setIndependentVenues(false);
    setVenue("");
    setQueryParams({});
    setShouldClearLiveSearch(true);
    resetReport();
    setCompanyMultiSelectKey((prevKey) => prevKey + 1);
    setDepartmentMultiSelectKey((prevKey) => prevKey + 1);
    window.localStorage.removeItem("departmentParams");
    window.localStorage.removeItem("companyParams");
  };

  useEffect(() => {
    let options = [];
    window.localStorage.removeItem("departmentParams");
    if (departments && Object.keys(departments).length > 0) {
      departments.sort((a, b) => a.name.localeCompare(b.name)).map(department => {
        options.push({
          text: department.name,
          label: department.name,
          name: department.name,
          value: department.id,
          id: department.id,
        });
      });
    }
    setDepartmentsOptions(options);
  }, [departments]);

  const getCompaniesOptions = useMemo(() => {
    if (!companies || companies.length === 0 || Object.entries(companies).length === 0) return [];
    return companies
      // .filter(company => company.corporate_account_id !== null)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(company => {
        return {
          label: company.name + " - " + company.code + " - " + company?.agency?.name,
          text: company.name + " - " + company.code + " - " + company?.agency?.name,
          value: company.id,
        };
      });
  }, [companies]);

  const getVenuesOptions = useMemo(() => {
    if (!venues || Object.entries(venues).length === 0) return [];
    return venues.sort((a, b) => a.name.localeCompare(b.name))
      .map(venue => ({
        text: venue.name,
        value: venue.id,
      }));
  }, [venues]);

  const toggleIndependentVenues = () => {
    setIndependentVenues(!independentVenues);
  };

  const handleDepartmentSelect = useCallback((e) => {
    const params = e ? e.map(dept => dept.value) : [];
    setDepartment(params);
    window.localStorage.setItem("departmentParams", params);
  }, []);

  const handleCompanySelect = useCallback(e => {
    const params = e ? e.map(com => com.value) : [];
    setCompany(params);
    window.localStorage.setItem("companyParams", params);
  }, []);

  const getAgenciesOptions = useMemo(() => {
    if (!isEdgeAdmin) return [];
    if (Object.entries(agenciesForSelect).length === 0 || agenciesForSelect.length === 0 || !agenciesForSelect) return [];
    return agenciesForSelect
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(agency => {
        return {
          label: agency.name,
          value: agency.id,
          text: agency.name,
        };
      });
  }, [agenciesForSelect, isEdgeAdmin]);

  return (
    <>
      <div className="flex flex-col lg:flex-wrap lg:flex-row justify-between align-bottom p-3 bg-white lg:items-end">
        <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
          <Date
            name="date_from"
            label={"Date From"}
            meta={{}}
            wrapperClassName="mb-0"
            classes={"min-h-9.5"}
            datePickerClass=""
            input={{
              onChange: e => {
                if (e) {
                  setDateFrom(format(e, "YYYY-MM-DD 00:00:00"));
                } else {
                  setDateFrom(null);
                }
              },
              value: dateFrom,
            }}
          />
          {error ? error.dateFrom ?
            <p className="text-warning bold text-xs mb-0">Please enter a start date.</p>
            : "" : ""}
        </div>
        <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
          <Date
            name="date_to"
            label={"Date To"}
            meta={{}}
            wrapperClassName="mb-0"
            classes={"min-h-9.5"}
            datePickerClass=""
            input={{
              onChange: e => {
                if (e) {
                  setDateTo(format(e, "YYYY-MM-DD 23:59:59"));
                } else {
                  setDateTo(null);
                }
              },
              value: dateTo,
            }}
          />
          {error ? error.dateTo ?
            <p className="text-warning bold text-xs mb-0">Please enter an end date.</p>
            : "" : ""}
        </div>
        {getAgenciesOptions.length > 0 ? (
          <div className="mb-2 xxl:mb-0 mr-4">
            <Select
              name="agency"
              label="Filter by Agency"
              component={Select}
              options={getAgenciesOptions}
              wrapperClassName="mb-0"
              input={{
                onChange: e => setSelectedAgency(e.target.value),
                value: selectedAgency,
              }}
              meta={{}}
            />
          </div>
        ) : null}
        {getCompaniesOptions.length > 0 ? (
          <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
            {isMultiFilters ? (
              <>
                <MultiSelect
                  key={companyMultiSelectKey}
                  label={"Filter by Company"}
                  cache={true}
                  cacheKey={"companyParams"}
                  options={getCompaniesOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  wrapperClassName="mb-0"
                  onChange={(e) => handleCompanySelect(e)}
                  allowSelectAll={true}
                  value={companyParams}
                  components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                  }}
                  style={{
                    searchBox: {
                      "border-radius": "0px",
                    },
                  }}
                />
              </>
            ) : (
              <Select
                name="company"
                label="Filter by Company"
                component={Select}
                options={getCompaniesOptions}
                wrapperClassName="mb-0"
                input={{
                  onChange: e => setCompany(e.target.value),
                  value: company,
                }}
                meta={{}}
              />
            )}
          </div>
        ) : null}
        {departmentsOptions.length > 0 && !isSuperAgent || (departmentsOptions.length > 0 && isSuperAgent && company && company.length === 1) ? (
          <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
            {isMultiFilters || (departmentsOptions.length > 0 && isAgent && company) ? (
              <>
                <MultiSelect
                  key={departmentMultiSelectKey}
                  name="department"
                  label="Filter by Department"
                  cache={true}
                  cacheKey={"departmentParams"}
                  value={departmentParams}
                  isMulti
                  options={departmentsOptions}
                  closeMenuOnSelect={false}
                  allowSelectAll={true}
                  hideSelectedOptions={false}
                  wrapperClassName="mb-0"
                  onChange={e => handleDepartmentSelect(e)}
                  autoComplete={"off"}
                  components={{
                    Option,
                    MultiValue,
                    ValueContainer,
                  }}
                  style={{
                    searchBox: {
                      "border-radius": "0px",
                    },
                  }}
                />
              </>
            ) : (
              <Select
                name="department"
                label="Filter by Department"
                placeholder={"Show all"}
                component={Select}
                options={departmentsOptions}
                wrapperClassName={"mb-0"}
                input={{
                  onChange: e => setDepartment(e.target.value),
                  value: department,
                }}
                meta={{}}
              />
            )}
          </div>
        ) : null}
        {showFilters.users && companyUserOptions.length > 0 ? (
          <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
            <Select
              name="user"
              label="Filter by User"
              component={Select}
              options={companyUserOptions}
              wrapperClassName="mb-0"
              input={{
                onChange: e => setCompanyUser(e.target.value),
                value: companyUser,
              }}
              meta={{}}
            />
          </div>
        ) : null}
        <>
          <div className="mb-2 xxl:mb-0">
            <ButtonPrimary disabled={shouldDisableSubmit} classes="mb-0" onClick={() => runReport(queryParams)}>
              {shouldDisableSubmit ? <Loading /> : "Run Report"}
            </ButtonPrimary>
          </div>
        </>
        <div className="mb-2 xxl:mb-0">
          <ButtonGrey classes="mb-0" onClick={resetFilters} title="Reset filters">
            <i className="fal fa-redo"></i>
          </ButtonGrey>
        </div>
        {showFilters.showCount && (
          <p>Total Results: {total}</p>
        )}
      </div>
      {advancedFilters ? (
        <>
          <div className={"flex flex-col text-xs mb-3 pt-0 px-3 pb-3 bg-white"}
               onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}>
            <span className={"pointer hover:text-brand"}>Advanced Filters</span>
          </div>
          <div
            className={showAdvancedFilters ? `flex-col lg:flex-wrap lg:flex-row justify-between justify-start align-bottom p-3 bg-white -mt-3 mb-3 lg:items-end flex` : `hidden`}>
            {showFilters.town && (
              <Input
                input={{
                  onChange: e => setTown(e.target.value),
                  value: town,
                }}
                meta={{}}
                name="search"
                label="Search Town"
                labelClassName="block uppercase font-heading text-xs mb-1"
                wrapperClassName="mb-2"
              />
            )}
            {showFilters.county && (
              <Input
                input={{
                  onChange: e => setCounty(e.target.value),
                  value: county,
                }}
                meta={{}}
                name="search"
                label="Search County"
                labelClassName="block uppercase font-heading text-xs mb-1"
                wrapperClassName="mb-2"
              />
            )}
            {showFilters.country && (
              <LiveSearchSimple
                fieldClassName="mb-2 xxl:mb-0 mr-2 w-full lg:w-48"
                name="country"
                label="Filter By Country"
                labelClassName="block uppercase font-heading mb-1 text-xs "
                auth
                url={`${window.API}/search/countries`}
                onChange={(country) => setCountry(country.id)}
                onClear={() => setCountry("")}
                shouldClearSearches={shouldClearLiveSearch}
              />
            )}
            {showFilters.hotelGroup && !independentVenues && (
              <LiveSearchSimple
                fieldClassName="mb-2 xxl:mb-0 mr-2 w-full lg:w-48"
                name="hotel_group"
                label="Filter By Hotel Group"
                labelClassName="block uppercase font-heading mb-1 text-xs "
                auth
                url={`${window.API}/search/hotel-groups?addIndependent=true`}
                onChange={(group) => setHotelGroup(group.id)}
                onClear={() => {
                  setHotelGroup("");
                  setVenue("");
                }}
                shouldClearSearches={shouldClearLiveSearch}
              />
            )}
            {independentVenues || hotelGroup ? (
              <LiveSearchSimple
                fieldClassName="mb-2 xxl:mb-0 mr-2 w-full lg:w-48"
                name="venue"
                label="Filter By Venue"
                labelClassName="block uppercase font-heading mb-1 text-xs "
                auth
                url={`${window.API}/hotel-group/venues?hotelGroup=${hotelGroup}`}
                onChange={v => setVenue(v.id)}
                shouldClearSearches={shouldClearLiveSearch}
              />
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
};

export default ReportFilters;
