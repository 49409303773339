import React, {Component} from 'react';
import EventsListTable from "./EventsListTable";
import {connect} from "react-redux";
import {isAgency, isCorporate, token, user, userAgencyObject} from "../../../../modules/auth";
import {
  corporateAccountBookingDesksUnload,
  eventsListUnload,
  fetchBookedEventsList,
  fetchCompaniesForEvents,
  fetchBookingDesks,
  fetchEventStatusList,
  fetchCorporateDepartments,
  corporateDepartmentsUnload,
  fetchCompany,
  companyUnload,
  fetchCorporateAccountUsers,
  unloadCorporateAccountUsers,
  unloadCompanyList, unloadCompanyBranches, fetchCompanyBranches,
} from "../../../../redux/actions";
import {Pagination} from "../../../../ui";
import EventFilters from "./EventFilters";
import Loading from "../../../../Components/Loading";
import {saveData} from "../../../../services/downloadFile";
import axios from "axios";
import {removeEmptySearchParams} from "../../../../utils";
import EventsChaseModal from "./EventsChaseModal";
import {
  agencyObject,
  canChaseEvent,
  canManageEventCompletion,
  canManageEventPreData,
  corporateAccountObject,
  isCompanyAdmin,
  isCompanyUser,
  isRoleEdgeAdmin,
  isSuperAgent,
  isAgencyAdmin,
  companyObject,
  isHeadOfEvents,
  hasCorporateAccount,
  hasActiveCorporateAccount,
  hasCorporateAccess,
  isHeadOfProcurement,
  isAgencyUser
} from "../../../../modules/permissions";
import BookingEventExportButtons from "./BookingEventExportButtons";
import {fetchVenuesFromHotelGroup, venuesListUnload} from "../../../../redux/actions/venues";

const mapStateToProps = state => {
  let agency = Object.entries(agencyObject(state.auth)).length > 0 ? agencyObject(state.auth) : companyObject(state.auth).agency;
  return {
    ...state.bookedEvents,
    ...state.bookedEventsPage,
    ...state.bookedEventsLastPage,
    ...state.eventStatus,
    ...state.agencyBookingDesks,
    ...state.corporateDepartments,
    ...state.venues,
    ...state.company,
    ...state.corporateAccountUsers,
    ...state.companyBranches,
    corporateAccount: corporateAccountObject(state.auth),
    user: user(state),
    agencyAccount: agency,
    token: token(state),
    companies: state.companyList.companiesForEvents ? state.companyList.companiesForEvents.data : [],
    canManageEventCompletion: canManageEventCompletion(state.auth),
    canManageEventPreData: canManageEventPreData(state.auth),
    canChaseEvent: canChaseEvent(state.auth),
    isCompanyUser: isCompanyUser(state.auth),
    isCompanyAdmin: isCompanyAdmin(state.auth),
    isAgencyAdmin: isAgencyAdmin(state.auth),
    isAgencyUser: isAgencyUser(state.auth),
    isCorporate: isCorporate(state),
    isEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isSuperAgent: isSuperAgent(state.auth),
    isHeadOfProcurement: isHeadOfProcurement(state.auth),
    isHeadOfEvents: isHeadOfEvents(state.auth),
    hasActiveCorporateAccount: hasActiveCorporateAccount(state.auth),
    hasCorporateAccess: hasCorporateAccess(state.auth),
    isAgency: isAgency(state),
    canViewCommissionValue: isRoleEdgeAdmin(state.auth) || isSuperAgent(state.auth) || isAgencyUser(state.auth) || isAgencyAdmin(state.auth),
    selectedAgencies: state.selectedAgencies.selectedAgencies,
    agencyObject: userAgencyObject(state)
  }
}

const mapDispatchToProps = {
  fetchBookedEventsList,
  fetchCorporateAccountUsers,
  eventsListUnload,
  fetchCompaniesForEvents,
  fetchEventStatusList,
  fetchBookingDesks,
  corporateAccountBookingDesksUnload,
  fetchCorporateDepartments,
  corporateDepartmentsUnload,
  fetchVenuesFromHotelGroup,
  venuesListUnload,
  fetchCompany,
  companyUnload,
  unloadCorporateAccountUsers,
  unloadCompanyList,
  unloadCompanyBranches,
  fetchCompanyBranches
}

class EventsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.bookedEventsPage,
      downloading: false,
      queryParams: null,
      showChaseModal: false,
      event: null,
      chaseContract: true
    }
    this.openChaseModal = this.openChaseModal.bind(this);
    this.closeChaseModal = this.closeChaseModal.bind(this);
  }

  componentDidMount() {
    this.props.fetchEventStatusList(this.props.token);
    this.props.fetchCompaniesForEvents(this.props.token);
    if (!this.props.isSuperAgent && this.props.corporateAccount && this.props.corporateAccount.id) {
      this.props.fetchCorporateDepartments(this.props.corporateAccount.id, this.props.token)
      this.props.fetchCorporateAccountUsers(this.props.corporateAccount.id, this.props.token)
      this.props.fetchCompanyBranches(this.props.user.company_id, this.props.token)
    }
    if (this.props.agencyAccount) {
      this.props.fetchBookingDesks(this.props.agencyAccount.id, this.props.token)
    }
  }


  getVenuesForHotelGroup = group => {
    return this.props.fetchVenuesFromHotelGroup(group, this.props.token)
  }

  filterBookedEvents = (queryParams) => {
    if (queryParams && queryParams.customFields) {
      for (var customField in queryParams.customFields) {
        if (!queryParams.customFields[customField] || queryParams.customFields[customField] === undefined) {
          delete queryParams.customFields[customField];
        }
      }

      if (Object.keys(queryParams.customFields).length === 0) {
        delete queryParams.customFields;
      }
    }

    if (performance.getEntriesByType("navigation")[0].type === 'reload') {
      this.setPage(1);
    }

    this.setState({
      queryParams: queryParams
    })
    return this.props.fetchBookedEventsList(this.props.token, this.props.bookedEventsPage, queryParams)
  }

  getDepartments = () => {
    const {
      corporateAccount,
      company,
      corporateDepartments,
      fetchCorporateDepartments,
      token,
      corporateDepartmentsUnload,
      isSuperAgent
    } = this.props;
    corporateDepartmentsUnload()
    if (!isSuperAgent && corporateAccount && corporateAccount.id) {
      fetchCorporateDepartments(corporateAccount.id, token)
      return corporateDepartments
    }

    if (company && company.corporate_account_id) {
      fetchCorporateDepartments(company.corporate_account_id, token)
      return corporateDepartments ?? []
    } else {
      corporateDepartmentsUnload()
      return [];
    }
  }

  getBookingDesks = () => {
    const {agencyAccount, company, corporateBookingDesks, fetchBookingDesks, token, corporateAccountBookingDesksUnload} = this.props;
    corporateAccountBookingDesksUnload()
    if (corporateBookingDesks && Object.entries(corporateBookingDesks).length > 0) {
      return corporateBookingDesks
    }
    if (agencyAccount && agencyAccount.booking_desks && Object.entries(agencyAccount.booking_desks).length > 0) {
      return agencyAccount.booking_desks;
    }
    if (company && company.agency_id) {
      fetchBookingDesks(company.agency_id, token)
      return corporateBookingDesks ?? []
    }
  }

  getBranches = () => {
    const {companyBranches, unloadCompanyBranches, token, company, fetchCompanyBranches} = this.props;
    unloadCompanyBranches();
    if (company && company.id) {
      fetchCompanyBranches(company.id, token);
      return companyBranches;
    }
  }

  getMeetingHosts = () => {
    const {company, fetchCorporateAccountUsers, token, unloadCorporateAccountUsers} = this.props;
    if (company && company.corporate_account_id) {
      fetchCorporateAccountUsers(company.corporate_account_id, token)
    } else {
      unloadCorporateAccountUsers()
    }
  }

  getCompany = companyId => {
    if (Number(companyId)) {
      this.props.fetchCompany(companyId, this.props.token).catch(() => this.props.companyUnload())
    } else {
      this.props.companyUnload();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      return this.props.fetchBookedEventsList(this.props.token, this.state.page, this.state.queryParams)
    }
    if(JSON.stringify(prevProps.selectedAgencies) !== JSON.stringify(this.props.selectedAgencies))
    {
      this.props.fetchBookedEventsList(this.props.token, this.state.page, this.state.queryParams)
      this.props.fetchCompaniesForEvents(this.props.token);
      if (this.props.agencyAccount) {
        this.props.fetchBookingDesks(this.props.agencyAccount.id, this.props.token)
      }
    }
  }

  componentWillUnmount() {
    this.props.eventsListUnload();
    this.props.venuesListUnload();
    this.props.corporateAccountBookingDesksUnload();
    this.props.corporateDepartmentsUnload();
    this.props.companyUnload();
    this.props.unloadCompanyList();
  }

  openChaseModal(event, contract) {
    this.setState({
      showChaseModal: true,
      event: event,
      chaseContract: contract
    })
  }

  closeChaseModal() {
    this.setState({
      showChaseModal: false,
      event: false
    })
  }

  setPage = (page) => {
    this.setState(prev => ({
      page,
      queryParams: {
        ...prev.queryParams,
        page
      }
    }))
  }

  handleExport = (type) => async () => {
    if (this.state.downloading) {
      return;
    }
    this.setState({
      downloading: true,
    })
    let queryParams = new URLSearchParams(this.state.queryParams);
    removeEmptySearchParams(queryParams);
    let queryString = queryParams.toString()

    try {
      const response = await axios.get(
        `${window.API}/event/export` + "?" + queryString,
        {
          responseType: 'blob',
          headers: {Authorization: `Bearer ${this.props.token}`},
        },
      );
      saveData(
        type === 'csv',
        response.data,
        `events-export.${type === 'csv' ? 'xlsx' : type}`,
      );
      this.setState({
        downloading: false,
      })

    } catch (err) {
      this.setState({
        downloading: false,
      })
      alert(`Sorry there was a problem fetching your ${type}`);
    }
  };

  render() {
    const {
      bookedEvents = [],
      companies,
      isFetchingBookedEvents,
      bookedEventsPage,
      bookedEventsLastPage,
      eventStatus = [],
      canManageEventPreData,
      canManageEventCompletion,
      canChaseEvent,
      isCompanyUser,
      isCompanyAdmin,
      canViewCommissionValue,
      corporateAccount,
      token,
      corporateDepartments,
      isEdgeAdmin,
      isAgencyAdmin,
      venues,
      company,
      agencyBookingDesks,
      isCorporate,
      hasActiveCorporateAccount,
      hasCorporateAccess,
      isHeadOfEvents,
      isHeadOfProcurement,
      corporateAccountUsers,
      isAgencyUser,
      isSuperAgent,
      isInternal,
      companyBranches,
      isAgency
    } = this.props;
    const {event, showChaseModal, chaseContract} = this.state;
    return (
      <div className="py-5">
        <div className="md:flex md:justify-between mb-5">
          {(isCorporate && hasActiveCorporateAccount && hasCorporateAccess) ?
              <h1>{(isCompanyAdmin || isHeadOfEvents || isHeadOfProcurement) ? 'Company' : 'My'} Events</h1>
              :
              <h1>Events</h1>
          }
        </div>
        <EventFilters
          companies={companies}
          filterBookedEvents={this.filterBookedEvents}
          currentPage={bookedEventsPage}
          eventStatus={eventStatus}
          isCompanyAccount={isCompanyAdmin || isCompanyUser}
          corporateAccount={corporateAccount}
          getBookingDesks={this.getBookingDesks}
          getCompany={this.getCompany}
          getDepartments={this.getDepartments}
          corporateDepartments={corporateDepartments}
          agencyBookingDesks={agencyBookingDesks}
          isEdgeAdmin={isEdgeAdmin}
          isAgencyAdmin={isAgencyAdmin}
          getVenuesForHotelGroup={this.getVenuesForHotelGroup}
          venues={venues}
          companyObject={company}
          canViewCommissionValue={canViewCommissionValue}
          isCorporate={isCorporate}
          corporateAccountUsers={corporateAccountUsers}
          getMeetingHosts={this.getMeetingHosts}
          getBranches={this.getBranches}
          companyBranches={companyBranches}
          selectedAgencies={this.props.selectedAgencies}
        />
        {isFetchingBookedEvents ? (
          <Loading/>
        ) : (
          <EventsListTable
            bookedEvents={bookedEvents}
            openModal={this.openChaseModal}
            canManageEventPreData={canManageEventPreData}
            canManageEventCompletion={canManageEventCompletion}
            canChaseEvent={canChaseEvent}
            isCompanyUser={isCompanyUser}
            isCompanyAdmin={isCompanyAdmin}
            isSuperAgent={isSuperAgent}
            isAgency={isAgency}
            isCompanyAccount={isCompanyAdmin || isCompanyUser}
            canViewCommission={isEdgeAdmin || isAgencyAdmin || isSuperAgent || isAgencyUser}
            token={token}
            canViewCommissionValue={canViewCommissionValue}
            agency={this.props.agencyObject}
          />
        )}
        {showChaseModal && (
          <EventsChaseModal event={event} closeModal={this.closeChaseModal} chaseContract={chaseContract}/>
        )}
        {/*<ButtonPrimary classes="mb-0" onClick={this.handleExport('csv')}>*/}
        {/*  Export to Excel*/}
        {/*</ButtonPrimary>*/}
        <Pagination
          current={bookedEventsPage}
          last={bookedEventsLastPage}
          onClick={this.setPage}
        />
        <BookingEventExportButtons
          queryParams={this.state.queryParams}
          isCompanyAccount={isCompanyUser || isCompanyAdmin}
          agency={this.props.agencyObject}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsListContainer);
